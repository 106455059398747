<template>
<div>
	<CRow>
		<CCol sm="12" md="12">
        <CCard>
          <CCardHeader>
           
            <CRow>
            <CCol col="6" class="text-left"><h4>View Roles</h4></CCol>
            <CCol col="6" class="text-right">              
              <b-button-group>
                 <b-button variant="warning" v-on:click="actionEdit()" v-if="seenBtnWarn">Edit</b-button>
                 <b-button v-on:click="actionCancel()" v-if="seenBtnCcel">Cancel</b-button>
                 <b-button variant="success" v-on:click="actionUpdate()" v-if="seenBtnUpdate">Update</b-button>
              </b-button-group> 
            </CCol> 
            </CRow> 

          </CCardHeader>
          <CCardBody> 
            <CRow>
                <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>           

            <CForm> 
              <CInput
                label="Group Name"
                v-model="groupName"
                :disabled="!isDisabled"
                horizontal
              /> 

              <CInput
                label="Descriptions"
                v-model="desc"
                :disabled="!isDisableddesc"
                horizontal
              /> 

              <div class="text-center">
                <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
              </div>
             
              <b-row>
                  <b-col sm="3">                    
                     <label for="input-small" >Roles</label>
                  </b-col> 
                  <b-col sm="5"> 
                      <div v-for="(role, index) in optRoles">
                        <span v-if=" index <= div">
                        <label><input type="checkbox" :disabled="!isDisabledrole"  style="width: 20px; height: 20px;" v-model="role.selected"> {{ role.name }}</label> 
                        </span>
                      </div>
                  </b-col>
                   <b-col sm="4">                    
                      <div v-for="(role, index) in optRoles"> 
                        <span v-if=" index > div">
                        <label><input type="checkbox" :disabled="!isDisabledrole"  style="width: 20px; height: 20px;" v-model="role.selected"> {{ role.name }}</label> 
                        </span>
                      </div>
                   </b-col>
                 </b-row><br/>



            </CForm>
         
           </CCardBody>
        </CCard>
      </CCol>

     </CRow>

</div>
</template>

<script> 
 import AdminService from '@/api/AdminService.js';
 import Multiselect from 'vue-multiselect';

  export default {  
    components: {      
      Multiselect     
    },       
    data() {
      return {  
          div:0,
          spinner : false,
          seenBtnCcel: false,
          seenBtnWarn: true,
          seenBtnUpdate:false,
          groupName: this.$route.params.id,
          desc:'',
          rolesgroup:[],
          isDisabled:false,
          isDisableddesc:false,
          isDisabledrole:false,
          optRoles:[],
          defRoles:[],
          baserole:[],
          isDisabled:false,           
          seen:false,
          msg :'',
          color:''          
        } ; 
    },
    created () { 
      
      this.spinner = true;
      this.getUserGroupList();
      setTimeout( () => this.getUserRoleList() , 3000);      

    },
    methods: {

      actionEdit(){
         this.seenBtnCcel=true;
         this.seenBtnWarn=false;
         this.seenBtnUpdate=true;
         this.isDisableddesc=true;
         this.isDisabledrole=true;
      },

      actionCancel(){
        this.seenBtnCcel=false;
        this.seenBtnWarn=true;
        this.seenBtnUpdate=false;
        this.isDisableddesc=false;
        this.isDisabledrole=false;

      },

      actionUpdate(){

        var roles = localStorage.getItem('roles');
        var eachrole = roles.split(",");

        if(eachrole.includes("ROLE_SUPER_ADMIN")){  
        
          var list = this.optRoles; var roles =[];
          for (var key in list) {  
            if(list[key].selected){
                var role = list[key].name; roles.push(role);
            }            
          }

          let params = {};
          params['groupName']   = this.groupName;
          params['description'] = this.desc;
          params['roles'] = roles;   
          this.updateUserGroup(params);
          this.actionCancel();
             
        } else { 
           this.msg   = 'Sorry, your role not allowed this action!'; 
           this.color = 'warning'; this.seen  = true;
           setTimeout( () =>  this.seen=false , 5000);
        }

      },

      sortByProperty(property){  
         return function(a,b){  
            if(a[property] > b[property])  
               return 1;  
            else if(a[property] < b[property])  
               return -1; 
            return 0;  
         }  
      },      

      updateUserGroup: function(params) {
        AdminService.updateUserGroup(params).then(resp => { //console.log(resp);

           if(resp.message==="SUCCESS"){                  
              this.msg   = "Update Roles Success!"; 
              this.color = 'success'; this.seen  = true;              
            } else {
              this.msg   =  !resp.data.message ? "Update Roles Failed!" : resp.data.message; 
              this.color = 'danger'; this.seen  = true;              
            }  
            setTimeout( () =>  this.seen=false , 5000);  
        }, error => {
          this.loading = false;
        });
      },

      getUserGroupList: function() {
        AdminService.getUserGroupList().then(resp => {

              var selected = this.$route.params.id;
              var list     = resp.payload; 

              for (var key in list) {
                if(list[key].name==selected){
                    var roles = list[key].roles; 
                    this.desc = list[key].description;
                    for (var key3 in roles) { 
                      var role   = roles[key3].role;                        
                      //let index  = baseroles.findIndex(baseroles => baseroles === role );
                      this.rolesgroup.push(role);                        
                    }
                }
              }

      }, error => {
          this.loading = false;
        });
      },

      getUserRoleList: function() {
        AdminService.getUserRoleList().then(resp => { 
           var list = resp.payload; var roles  = [];  var baseroles=[];
           for (var key in list) { 
              var role = list[key].role;
              var selected = this.rolesgroup.includes(role) ? true : false;
              roles.push({ name: role, selected : selected }); 
              baseroles.push(role);             
           }
           roles.sort(this.sortByProperty("name"));
           this.optRoles = roles;           

           this.div  = (Math.ceil(roles.length / 2)) - 1;           
           
           this.spinner = false;
        }, error => {
          this.loading = false;
        });
      },
      
    }
  }
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>